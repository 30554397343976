import {
  selectIsLocalVideoEnabled,
  useHMSStore,
  selectLocalPeer,
} from "@100mslive/react-sdk";
import {
  Avatar,
  StyledVideoTile,
  Video,
  useBorderAudioLevel,
  useTheme,
} from "@100mslive/roomkit-react";
import { AudioVideoToggle } from "./AudioVideoToggle";
import { Audio } from "react-loader-spinner";

const controlStyles = {
  bottom: "10px",
  left: "50%",
  transform: "translate(-50%, 0)",
  display: "flex",
  "& > * + *": {
    marginRight: "0",
    marginLeft: "0.5rem",
  },
  backgroundColor: "unset",
};

export const PreviewTile = ({ name, videoInput }: any) => {
  const localPeer = useHMSStore(selectLocalPeer);

  const borderAudioRef = useBorderAudioLevel(localPeer?.audioTrack);
  const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
  const {
    aspectRatio: { width, height },
  } = useTheme();

  return (
    <StyledVideoTile.Container
      css={{
        aspectRatio: width / height,
        width: "unset",
        margin: "$1",
        maxHeight: "340px",
        "@sm": {
          height: "unset",
          width: "min(320px, 80%)",
          maxWidth: "70%",
        },
      }}
      ref={borderAudioRef}
    >
      {localPeer ? (
        <>
          {/* {!isVideoOn ? (
            <Avatar
              name={name}
              data-testid="preview_avatar_tile"
              css={{
                position: "absolute",
                width: "48px",
                color: "White",
                backgroundColor: "unset",
              }}
            />
          ) : null} */}
          <Video
            mirror={true}
            trackId={localPeer.videoTrack}
            data-testid="preview_tile"
          />
          <StyledVideoTile.AttributeBox css={controlStyles}>
            <AudioVideoToggle compact />
          </StyledVideoTile.AttributeBox>
        </>
      ) : (
        <Audio
          height="100"
          width="100"
          color="#FFFFFF"
          ariaLabel="audio-loading"
          wrapperStyle={{}}
          wrapperClass="wrapper-class"
          visible={true}
        />
      )}
    </StyledVideoTile.Container>
  );
};
